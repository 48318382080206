import React from 'react';
import * as styles from './historyPage.module.scss';
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';

const HistoryPage = () => {
  const data = useStaticQuery(graphql`
    {
      allContentfulHistory(sort: { fields: createdAt, order: DESC }) {
        nodes {
          info
          year
          img {
            gatsbyImage(quality: 100, width: 500, placeholder: BLURRED)
          }
        }
      }
    }
  `);

  const {
    allContentfulHistory: { nodes },
  } = data;

  return (
    <section>
      <div className='headerContainer'>
        <h1>
          Unsere <span className='yellow'>Geschichte</span>
        </h1>
      </div>
      <div className='container'>
        {nodes.map((item) => {
          return (
            <div className={`row  my-4 ${styles.row}`}>
              <div className={`col-md-2 ${styles.circles}`}>
                <FontAwesomeIcon
                  icon={faCircle}
                  className={`yellow my-2 ${styles.faCircle}`}
                />
                <FontAwesomeIcon
                  icon={faCircle}
                  className={`yellow my-2 ${styles.faCircle}`}
                />
                <FontAwesomeIcon
                  icon={faCircle}
                  className={`yellow my-2 ${styles.faCircle}`}
                />
                {item.year}
                <FontAwesomeIcon
                  icon={faCircle}
                  className={`yellow my-2 ${styles.faCircle}`}
                />
                <FontAwesomeIcon
                  icon={faCircle}
                  className={`yellow my-2 ${styles.faCircle}`}
                />
                <FontAwesomeIcon
                  icon={faCircle}
                  className={`yellow my-2 ${styles.faCircle}`}
                />
              </div>
              <div className='col-md-6'>
                <GatsbyImage image={item.img.gatsbyImage} />
              </div>
              <div className='col-sm-4 pt-2'>
                <p>{item.info}</p>
              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default HistoryPage;
