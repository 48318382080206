// extracted by mini-css-extract-plugin
export var buttonMd = "historyPage-module--button-md--49b08";
export var carousel = "historyPage-module--carousel--46176";
export var circles = "historyPage-module--circles--1bff0";
export var faCircle = "historyPage-module--faCircle--af6e8";
export var grey = "historyPage-module--grey--992bb";
export var greyFont = "historyPage-module--greyFont--2c762";
export var headerContainer = "historyPage-module--headerContainer--85932";
export var row = "historyPage-module--row--62e88";
export var spanBold = "historyPage-module--spanBold--bec27";
export var yellow = "historyPage-module--yellow--f5c2d";