import React from 'react';
import Layout from '../components/layout';
import HistoryPage from '../components/HistoryPage/historyPage';
import SEO from '../components/SEO/SEO';

const Geschichte = () => {
  return (
    <Layout>
      <SEO
        title='Geschichte'
        description='Die Geschichte von Bowman Automobile AG.'
      />
      <HistoryPage />
    </Layout>
  );
};

export default Geschichte;
